import _ from 'lodash';
import { mapGetters } from 'vuex';
import graphUtils from '../../services/graphQLUtilities/graphQLUtilities';

export default {
  data() {
    return {
      search: '',
      headers: [
        {
          text: 'Name',
          align: 'left',
          sortable: true,
          value: 'LastName',
          width: '20%'
        },
        { text: 'CommonId', value: 'CommonId', width: '7%' },
        { text: 'Email Address', value: 'EmailAddress', width: '20%' },
        { text: 'Team', value: 'SubTeam', width: '15%' },
        { text: 'Reason', value: 'Reason', width: '10%' },
        { text: 'Roles', value: 'Roles', width: '25%', sortable: false },
        { text: '', width: '3%', value: 'blank' }
      ],
      appUsers: [],
      currentRoles: [
        { Name: 'Admin', Id: 1 },
        { Name: 'User', Id: 2 },
        { Name: 'Read-Only', Id: 3 },
        { Name: 'View History', Id: 4 }
      ],
      application: {},
      isLoading: true,
      removeDialog: false,
      addRoleDialog: false,
      selectedRole: null,
      selectedUser: null,
      currentUsers: [],
      isProcessing: false,
      autoCompleteUsers: [],
      autoCompleteSelect: '',
      autoCompleteSearch: '',
      autoCompleteLoading: false,
    };
  },
  watch: {
    $route: { handler: 'loadUsers', immediate: true },
    autoCompleteSearch: async function(val) {
      if (val && val.length > 2) {
        this.autoCompleteLoading = true;
        await this.listTeamMembersByName(val);
        this.autoCompleteLoading = false;
      }
    }
  },
  methods: {
    async loadUsers() {
      this.isLoading = true;
      this.appUsers = [];
      await this.loadCurrentApplication();
      await graphUtils.listApplicationPermissions(this.application.Id);
      let appPermissions = this.$store.state.user.applicationPermissions;
      for (let i = 0; i < appPermissions.length; i++) {
        let userInfo = await graphUtils.getUser(appPermissions[i].UserId);
        if (userInfo) {
          userInfo.Roles = [];
          userInfo.Roles.push(appPermissions[i].Role);
          if (_.includes(appPermissions[i].Roles, 4)) {
            userInfo.Roles.push(4);
          }
          userInfo.Reason = appPermissions[i].Reason;
          userInfo.Roles = this.setRoles(userInfo);
          this.appUsers.push(userInfo);
        }
        this.currentUsers = _.uniqBy(this.appUsers, 'CommonId');
      }
      this.isLoading = false;
    },
    async loadCurrentApplication() {
      await graphUtils.getApplication(this.user.commonid, this.$route.params.id);
      this.application = this.$store.state.applications.currentApplication;
    },
    setChipColor(role) {
      switch (role.Name) {
        case 'User':
          return 'primary';
        case 'Admin':
          return 'success';
        case 'Read-Only':
          return 'info';
        default:
          return 'warning';
      }
    },
    openRemoveDialog(role, user) {
      this.removeDialog = true;
      this.selectedRole = role;
      this.selectedUser = user;
    },
    openAddRoleDialog(user) {
      this.addRoleDialog = true;
      this.selectedUser = user;
    },
    async removeRole() {
      this.isProcessing = true;
      let role = this.selectedRole;
      let user = this.selectedUser;
      let index = _.findIndex(user.Roles, (o) => {
        return _.isMatch(o, role);
      });
      await graphUtils.removeRole(this.application.Id, this.user.commonid, user.CommonId, role.Id);
      let userIndex = _.findIndex(this.currentUsers, (u) => {
        return _.isMatch(u, this.selectedUser);
      });
      user.Roles.splice(index, 1);
      this.$set(this.currentUsers, userIndex, user);
      this.removeDialog = false;
      this.isProcessing = false;
      if (this.selectedUser.CommonId === String(this.user.commonid)) {
        this.$snotify.success(`You have removed ${this.selectedRole.Name} from yourself.`);
      } else {
        this.$snotify.success(`${role.Name} removed from ${user.FirstName} ${user.LastName}.`);
      }
      this.clearSelections();
    },
    async checkIfUserHasRole() {
      this.isProcessing = true;
      if (!this.selectedRole) {
        this.$snotify.error('Please select a role.');
        this.isProcessing = false;
      } else {
        if (
          _.findIndex(this.selectedUser.Roles, (o) => {
            return _.isMatch(o, this.selectedRole);
          }) > -1
        ) {
          this.$snotify.error(`${this.selectedUser.FirstName} ${this.selectedUser.LastName} already has ${this.selectedRole.Name} assigned.`);
          this.isProcessing = false;
          this.addRoleDialog = false;
          this.clearSelections();
        } else {
          await graphUtils.addRole(this.application.Id, this.user.commonid, this.selectedUser.CommonId, this.selectedRole.Id);
          let userIndex = _.findIndex(this.currentUsers, (u) => {
            return _.isMatch(u, this.selectedUser);
          });
          if (this.selectedRole.Id === 4) {
            this.selectedUser.Roles.push(this.selectedRole);
          } else {
            let newRoles = _.remove(this.selectedUser.Roles, (role) => {
              return role.Id === 4;
            });
            newRoles.splice(0, 0, this.selectedRole);
            this.selectedUser.Roles = newRoles;
          }
          this.$set(this.currentUsers, userIndex, this.selectedUser);
          if (this.selectedUser.CommonId === String(this.user.commonid)) {
            this.$snotify.success(`You have successfully added ${this.selectedRole.Name} to yourself.`);
          } else {
            this.$snotify.success(`${this.selectedRole.Name} successfully added to ${this.selectedUser.FirstName} ${this.selectedUser.LastName}.`);
          }
        }
        this.isProcessing = false;
        this.addRoleDialog = false;
        this.clearSelections();
      }
    },
    clearSelections() {
      this.selectedRole = null;
      this.selectedUser = null;
    },
    setRoles(user) {
      let textRoles = [];
      user.Roles.forEach((role) => {
        switch (role) {
          case 1:
            textRoles.push({ Name: 'Admin', Id: 1 });
            break;
          case 2:
            textRoles.push({ Name: 'User', Id: 2 });
            break;
          case 3:
            textRoles.push({ Name: 'Read-Only', Id: 3 });
            break;
          case 4:
            textRoles.push({ Name: 'View History', Id: 4 });
            break;
        }
      });
      return textRoles;
    },
    async listTeamMembersByName(teamMemberName) {
      this.autoCompleteUsers = [];
      await this.$store.dispatch('user/listTeamMembersByName', {
        query: `query { 
          listTeamMembersByName(TeamMemberName: "${teamMemberName}") {
            FirstName
            LastName
            CommonId
            EmailAddress
            SubTeam
            BadgePhotoUrl
          }
        }`
      });
      let teamMembers = this.$store.state.user.users;
      for (let i = 0; i < teamMembers.length; i++) {
        let autoCompleteUser = {
          text: teamMembers[i].FirstName + ' ' + teamMembers[i].LastName,
          value: teamMembers[i].CommonId,
          badgePhotoUrl: teamMembers[i].BadgePhotoUrl
        };
        this.autoCompleteUsers.push(autoCompleteUser);
      }
    },
    async addNewUser() {
      if (
        _.findIndex(this.currentUsers, (o) => {
          return _.isMatch(o.UserId, this.autoCompleteSelect);
        }) > -1
      ) {
        this.$snotify.error('This user already has access to this application.');
        this.isProcessing = false;
        this.addRoleDialog = false;
        this.clearSelections();
      } else {
        let user = {
          CommonId: this.autoCompleteSelect,
          Roles: []
        };
        this.openAddRoleDialog(user);
      }
    }
  },
  computed: {
    ...mapGetters({
      user: ['auth/user']
    })
  }
};
